import React, { useState, useEffect } from "react";
import { Menu, message } from "antd";
import config from "src/commons/config-hoc";
import {getLoginUser} from "@ra-lib/admin";
import styles from "./style.less";

export default config({
    path: "/front/idss",
    title: "智能决策",
})(function IdssComponent(props) {
    const [menuItems, setMenuItems] = useState([]);
    const [currentUrl, setCurrentUrl] = useState("");

    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await props.ajax.get("/api/idss/availablebots", { orgId: getLoginUser().orgId });
                console.log(response.data)
                // 检查数据是否有效
                if (response.data) {
                    const items = response.data;
                    const transformedItems = items.map(item => ({
                        key: item.id,
                        label: item.appLabel,
                        url: item.appUrl,
                    }));

                    setMenuItems(transformedItems);

                    // 默认选择第一个菜单项
                    if (transformedItems.length > 0) {
                        setCurrentUrl(transformedItems[0].url);
                    }
                } else {
                    throw new Error("无效的数据格式");
                }
            } catch (error) {
                console.error("Error fetching menu items:", error);
                message.error(error.message || "获取菜单项失败，请稍后重试。");
            }
        };

        fetchMenuItems();
    }, [props.ajax]);

    const handleMenuClick = (e) => {
        const clickedItem = menuItems.find((item) => item.key === e.key);
        if (clickedItem) {
            setCurrentUrl(clickedItem.url);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
                width: "100%",
                overflow: "hidden",
            }}
        >
            {/* 右侧 iframe 显示区域 */}
            <div style={{ flex: 1, overflow: "hidden", padding: "5px"}}>
                {currentUrl ? (
                    <iframe
                        src={currentUrl}
                        style={{
                            width: "100%",
                            height: "95%",
                            border: "none",
                            borderRadius: "5px",
                            overflow: "hidden",
                        }}
                        title="智能决策"
                    />
                ) : (
                    <div>加载中...</div>
                )}
            </div>
        </div>
    );
});
