import React, {useState, useEffect} from "react";
import {ConfigProvider} from "antd";
import {Helmet} from "react-helmet";
import {Provider} from "react-redux";
import zhCN from "antd/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn"; // 解决antd日期相关组件国际化问题
import {
    ComponentProvider, Loading, getLoginUser, setLoginUser,
} from "@ra-lib/admin";
import AppRouter from "./router/AppRouter";
import {APP_NAME, CONFIG_HOC, IS_MOBILE} from "./config";
import {store} from "./models";
import {
    saveCollectedMenu, getCollectedMenus, getPermissions,
} from "src/api";
import theme from "src/theme.less";
import "./App.less";

// 设置语言
moment.locale("zh-cn");

// 设置 Modal、Message、Notification rootPrefixCls。
ConfigProvider.config({
    prefixCls: theme.antPrefix,
});

export default function App(props) {
    const {children} = props;
    const [loading, setLoading] = useState(true);
    const [menus, setMenus] = useState([]);
    const [collectedMenus, setCollectedMenus] = useState(CONFIG_HOC.showCollectedMenus ? [] : null);

    async function handleMenuCollect(menu, collected) {
        await saveCollectedMenu({menuId: menu.id, collected});

        const collectedMenus = await getCollectedMenus();
        setCollectedMenus(collectedMenus);
    }

    useEffect(() => {
        (async () => {
            if (!CONFIG_HOC.showCollectedMenus) return;

            const collectedMenus = await getCollectedMenus();
            setCollectedMenus(collectedMenus);
        })();
    }, []);

    // 一些初始化工作
    useEffect(() => {
        (async () => {
            try {
                const loginUser = getLoginUser();

                // 用户存在，获取菜单
                if (loginUser) {
                    // const menus
                    const menus = loginUser.frontResources.map((item) => {

                        if (item.resourceId === "1101") {
                            // 新画像
                            return {
                                id: "profile", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === "智能决策") {
                            return {
                                id: "idss", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.resourceId === "11") {
                            // 老画像
                            return {
                                id: "report", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === "排行榜") {
                            return {
                                id: "rank", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === "绩效榜(旧版)") {
                            return {
                                id: "rank-achi", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === "流程挖掘") {
                            return {
                                id: "pm", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === "工作档案") {
                            return {
                                id: "wf", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === "驾驶舱") {
                            return {
                                id: "cockpit", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === "分组榜") {
                            return {
                                id: "rank-group", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === "绩效榜") {
                            return {
                                id: "achievement", title: item.name, path: JSON.parse(item.config).link
                            };
                        }
                        if (item.name === '员工持续改进') {
                            return {
                                id: "employees-improvement", title: item.name, path: JSON.parse(item.config).link
                            }
                        } else {
                            return {}
                        }
                    }).filter(Boolean);
                    setMenus(menus)
                    loginUser.permissions = await getPermissions();
                    setLoginUser(loginUser);
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                throw e;
            }
        })();
    }, []);

    return (<Provider store={store}>
        <ConfigProvider locale={zhCN} prefixCls={theme.antPrefix}>
            <Helmet title={APP_NAME}/>
            {loading ? <Loading progress={false} spin/> : null}

            <ComponentProvider
                prefixCls={theme.raLibPrefix}
                layoutPageOtherHeight={CONFIG_HOC.pageOtherHeight}
                isMobile={IS_MOBILE}
            >
                {children ? (children) : (<AppRouter
                    menus={menus}
                    collectedMenus={collectedMenus}
                    onMenuCollect={handleMenuCollect}
                />)}
            </ComponentProvider>
        </ConfigProvider>
    </Provider>);
}
