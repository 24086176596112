// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".leftMenu_OhEJs {\n  width: 210px;\n  padding-left: 5px;\n  padding-top: 10px;\n  background: inherit;\n  border-right: 5px;\n}\n.leftMenu_OhEJs .leftMenuItem_1b9wN {\n  background: inherit;\n}\n", "",{"version":3,"sources":["webpack://src/pages/idss/style.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AANA;EAOQ,mBAAA;AAER","sourcesContent":[".leftMenu {\n    width: 210px;\n    padding-left: 5px;\n    padding-top: 10px;\n    background: inherit;\n    border-right: 5px;\n    .leftMenuItem {\n        background: inherit;\n    }\n}\n@packageName: farcloud;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftMenu": "leftMenu_OhEJs",
	"leftMenuItem": "leftMenuItem_1b9wN"
};
export default ___CSS_LOADER_EXPORT___;
